export type AppLocaleOnView = { label: string; _id: ISO6391LanguageCode };

//contains country code (ISO 3166-1 alpha-2) and optionally language code (ISO 639-1)
export type LocaleCountryAndLanguageCode =
  | 'en'
  | 'ar-AE'
  | 'bg-BG'
  | 'hr-HR'
  | 'cs-CZ'
  | 'fr'
  | 'de-DE'
  | 'he-IL'
  | 'hu-HU'
  | 'no'
  | 'pl'
  | 'pt-PT'
  | 'ro'
  | 'ru-UA'
  | 'sk-SK'
  | 'es'
  | 'tr-TR'
  | 'uk-UA';

export const LOCALE_LANGUAGES: Record<
  ISO6391LanguageCode,
  LocaleCountryAndLanguageCode
> = {
  en: 'en',
  ar: 'ar-AE',
  bg: 'bg-BG',
  hr: 'hr-HR',
  cs: 'cs-CZ',
  fr: 'fr',
  de: 'de-DE',
  he: 'he-IL',
  hu: 'hu-HU',
  no: 'no',
  pl: 'pl',
  pt: 'pt-PT',
  ro: 'ro',
  ru: 'ru-UA',
  sk: 'sk-SK',
  es: 'es',
  tr: 'tr-TR',
  uk: 'uk-UA',
};

export type ISO6391LanguageCodeOption =
  | 'en'
  | 'bg'
  | 'hr' //croatia
  | 'ar'
  | 'fr'
  | 'de'
  | 'cs' //czech
  | 'he'
  | 'hu'
  | 'no'
  | 'pl'
  | 'pt'
  | 'ro'
  | 'ru'
  | 'sk'
  | 'es'
  | 'tr'
  | 'uk'; //ukraine

export enum ISO6391LanguageCode {
  en = 'en',
  ar = 'ar',
  bg = 'bg',
  hr = 'hr',
  cs = 'cs',
  fr = 'fr',
  de = 'de',
  he = 'he',
  hu = 'hu',
  no = 'no',
  pl = 'pl',
  pt = 'pt',
  ro = 'ro',
  ru = 'ru',
  sk = 'sk',
  es = 'es',
  tr = 'tr',
  uk = 'uk',
}

export const ISO6391LanguageCodes: ISO6391LanguageCode[] = Object.values(
  ISO6391LanguageCode
);

type LanguageLabel =
  | 'English'
  | 'العربية'
  | 'Български'
  | 'Hrvatski'
  | 'Čeština'
  | 'Français'
  | 'Deutsch'
  | 'עברית'
  | 'Magyar'
  | 'Norsk'
  | 'Polski'
  | 'Português'
  | 'Română'
  | 'Русский'
  | 'Slovenčina'
  | 'Español'
  | 'Türkçe'
  | 'Українська';

export const LANGUAGE_LABELS: Record<ISO6391LanguageCode, LanguageLabel> =
  {
    en: 'English',
    ar: 'العربية',
    bg: 'Български',
    hr: 'Hrvatski',
    cs: 'Čeština',
    fr: 'Français',
    de: 'Deutsch',
    he: 'עברית',
    hu: 'Magyar',
    no: 'Norsk',
    pl: 'Polski',
    pt: 'Português',
    ro: 'Română',
    ru: 'Русский',
    sk: 'Slovenčina',
    es: 'Español',
    tr: 'Türkçe',
    uk: 'Українська',
  };
