import arCalendarLocale from 'date-fns/locale/ar';
import bgCalendarLocale from 'date-fns/locale/bg';
import csCalendarLocale from 'date-fns/locale/cs';
import deCalendarLocale from 'date-fns/locale/de';
import enCalendarLocale from 'date-fns/locale/en-US';
import esCalendarLocale from 'date-fns/locale/es';
import frCalendarLocale from 'date-fns/locale/fr';
import heCalendarLocale from 'date-fns/locale/he';
import hrCalendarLocale from 'date-fns/locale/hr';
import huCalendarLocale from 'date-fns/locale/hu';
import plCalendarLocale from 'date-fns/locale/pl';
import ptCalendarLocale from 'date-fns/locale/pt';
import roCalendarLocale from 'date-fns/locale/ro';
import ruCalendarLocale from 'date-fns/locale/ru';
import skCalendarLocale from 'date-fns/locale/sk';
import trCalendarLocale from 'date-fns/locale/tr';
import ukCalendarLocale from 'date-fns/locale/uk';
// why nb https://github.com/date-fns/date-fns/issues/2963
import noCalendarLocale from 'date-fns/locale/nb';
import { startWeekWithMonday } from 'helpers/date';
import { ISO6391LanguageCodeOption } from 'shared/types/locale';

export const calendarLocales: Record<ISO6391LanguageCodeOption, Locale> = {
  en: startWeekWithMonday(enCalendarLocale),
  ar: arCalendarLocale,
  bg: bgCalendarLocale,
  hr: hrCalendarLocale,
  cs: csCalendarLocale,
  fr: frCalendarLocale,
  de: deCalendarLocale,
  he: heCalendarLocale,
  hu: huCalendarLocale,
  no: noCalendarLocale,
  pl: plCalendarLocale,
  pt: ptCalendarLocale,
  ro: roCalendarLocale,
  ru: ruCalendarLocale,
  sk: skCalendarLocale,
  es: esCalendarLocale,
  tr: trCalendarLocale,
  uk: ukCalendarLocale,
};
