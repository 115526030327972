import { DateTime } from 'luxon';
import { DayMomentValidation, TimeSetter } from 'shared/types/time';

export enum DateFormats {
  'shortISOString' = 'yyyy-MM-dd',
  'isoWithTimezone' = `yyyy-MM-dd'T'HH:mm:ssXXXX`,
  'shortString' = 'dd/MM/yyyy',
}

export function shortISOStringToProjectTimezonedString(
  date: string,
  timezone: string,
  daymoment: DayMomentValidation | undefined
): string {
  const luxondate = DateTime.fromFormat(date, DateFormats.shortISOString, {
    zone: timezone,
  });

  if (!daymoment) {
    return localTimeInProjectTimezone(luxondate, timezone);
  }

  return timeSetter[daymoment](luxondate).toString();
}

export function shortISOStringToProjectTimezonedDate(
  date: string,
  timezone: string,
  daymoment: DayMomentValidation
): DateTime {
  return timeSetter[daymoment](
    DateTime.fromFormat(date, DateFormats.shortISOString, {
      zone: timezone,
    })
  );
}

export function isoWithTimezoneToMuiDatepicker(
  date: DateFormats.isoWithTimezone | undefined | null
): DateTime | null {
  return date ? DateTime.fromISO(date) : null;
}

export function setDayStart(date: DateTime): DateTime {
  return date.set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
}

function setDayEnd(date: DateTime): DateTime {
  return date
    .set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
    .plus({ days: 1 })
    .minus({ milliseconds: 1 });
}

const timeSetter: TimeSetter = {
  [DayMomentValidation.start]: setDayStart,
  [DayMomentValidation.end]: setDayEnd,
};

function localTimeInProjectTimezone(
  date: DateTime,
  timezone: string
): string {
  const now = DateTime.now().setZone(timezone);
  const selectedDateWithCurrentTime = now.set({
    year: date.year,
    month: date.month,
    day: date.day,
  });

  return selectedDateWithCurrentTime.toString();
}

function shortStringToProjectTimezonedString(
  date: string | null | undefined,
  timezone: string,
  daymoment: DayMomentValidation | undefined
): string | null {
  if (!date) {
    return null;
  }
  const luxondate = DateTime.fromFormat(date, 'dd/MM/yyyy', {
    zone: timezone,
  });

  if (!daymoment) {
    return localTimeInProjectTimezone(luxondate, timezone);
  }

  return timeSetter[daymoment](luxondate).toString();
}

export function isoToProjectTimezonedString(
  date: string,
  timezone: string,
  daymoment?: DayMomentValidation
): DateFormats.isoWithTimezone {
  const luxondate = isoToProjectTimezonedDate(date, timezone, daymoment);
  return (
    luxondate && (luxondate.toString() as DateFormats.isoWithTimezone)
  );
}

export function isoToProjectTimezonedDate(
  date: string,
  timezone: string,
  daymoment?: DayMomentValidation
): DateTime {
  const luxondate = DateTime.fromISO(date, {
    zone: timezone,
  });

  if (!daymoment) {
    return luxondate;
  }

  return timeSetter[daymoment](luxondate);
}
/*
Creates exactly the same datetime, but with stripped timezone information
Useful for Excel dates (https://github.com/exceljs/exceljs/issues/486)
Examples:
isoToUTCDate('2022-05-22T00:00:00.000+03:00', 'Europe/Istanbul') => '2022-05-22T00:00:00.000Z'
isoToUTCDate('2022-05-17T00:00:00.000+02:00', 'Europe/Warsaw) => '2022-05-17T00:00:00.000Z'
*/
export function isoToUTCDate(date: string, timezone: string): DateTime {
  const timezonedDate = isoToProjectTimezonedDate(date, timezone);

  return timezonedDate.plus({ minutes: timezonedDate.offset });
}

export function datepickerDateToProjectTimezonedString(
  date: Date | null | undefined,
  timezone: string,
  daymoment: DayMomentValidation | undefined
): string | null {
  if (!date) {
    return null;
  }

  // we ignore datepicker timezone and use the date as it would be in a project timezone
  const luxondate = DateTime.fromJSDate(date).setZone(timezone, {
    keepLocalTime: true,
  });

  if (!daymoment) {
    return localTimeInProjectTimezone(luxondate, timezone);
  }

  return timeSetter[daymoment](luxondate).toString();
}

export function dateTimeToDayMomentString(
  date: DateTime,
  daymoment: DayMomentValidation
): string | null {
  return timeSetter[daymoment](date).toString();
}

export function dateTimeToDayMomentDateTime(
  date: DateTime,
  daymoment: DayMomentValidation
): DateTime | null {
  return timeSetter[daymoment](date);
}

export function isValidDate(date: any): boolean {
  return DateTime.isDateTime(date);
}

export function isoToDatepickerDecievedDate(
  date: string | null | undefined,
  timezone: string
): string | null {
  if (!date) {
    return null;
  }

  const projectDate = DateTime.fromISO(date, {
    zone: timezone,
  });

  const now = DateTime.now().setZone(timezone);
  const localUnmovedDate = now.set({
    year: projectDate.year,
    month: projectDate.month,
    day: projectDate.day,
    hour: projectDate.hour,
    minute: projectDate.minute,
    second: projectDate.second,
    millisecond: projectDate.millisecond,
  });

  return localUnmovedDate.toString();
}

export function isoToMuiDatepickerDate(
  date: string | null | undefined,
  timezone: string
): DateTime | null {
  if (!date) {
    return null;
  }

  const timezonedDate = DateTime.fromISO(date, {
    zone: timezone,
  });

  return timezonedDate;
}

export function toStandardDateDisplayStringFormat(
  date: string | DateTime,
  timezone: string
): string {
  if (typeof date === 'string') {
    date = DateTime.fromISO(date, {
      zone: timezone,
    });
  }

  return date.toLocaleString(DateTime.DATE_SHORT, {
    locale: 'en-gb',
  });
}

export function toShortISO(date: DateTime): string {
  return date.toFormat(DateFormats.shortISOString);
}

export function isoStringToShortISO(
  isoString?: string | null
): string | undefined {
  return isoString?.slice(0, 10);
}

export function nowISO(): string {
  return DateTime.now().toISO();
}

export function nowTimezonedDateTime(
  timezone: string,
  daymoment?: DayMomentValidation
): DateTime {
  return daymoment
    ? timeSetter[daymoment](DateTime.now().setZone(timezone))
    : DateTime.now().setZone(timezone);
}

export function isoToLocalDate(string: string): Date {
  return new Date(DateTime.fromISO(string).valueOf());
}

export function onDayStart(date: DateTime): DateTime {
  return date.startOf('day');
}

export function onDayEnd(date: DateTime): DateTime {
  return date.endOf('day');
}

export function toDayStartInTimezone(
  date: Date,
  projectTimezone: string
): Date {
  return DateTime.fromJSDate(date, { zone: projectTimezone })
    .startOf('day')
    .toJSDate();
}

export function toDayEndInTimezone(
  date: Date,
  projectTimezone: string
): Date {
  return DateTime.fromJSDate(date, { zone: projectTimezone })
    .endOf('day')
    .toJSDate();
}

export enum DateFormatFromUnit {
  'day' = 'yyyy-MM-dd',
  'month' = 'yyyy-MM',
  'year' = 'yyyy',
}
