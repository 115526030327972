import { Direction } from '@mui/material';
import { IntlShape } from 'react-intl';
import { anyToAppLocaleOnView } from 'shared/utils/locale/anyLocaleCodeToAppLocaleOnView';
import { isSupportedLocale } from 'shared/utils/locale/isSupportedLocale';
import {
  AppLocaleOnView,
  ISO6391LanguageCode,
} from '../shared/types/locale';

const browserLanguageMatchesLocale = (locale: string): boolean => {
  const browserLanguage = navigator.language;
  return (
    browserLanguage === locale || browserLanguage.startsWith(`${locale}-`)
  );
};

const setHtmlRootLangAttribute = (locale: ISO6391LanguageCode): void => {
  document.documentElement.lang = locale;
};

export const removeEscapingBackslash = <T>(json: any): T => {
  Object.entries(json).forEach(([key, val]: [string, unknown]) => {
    if ((val as string)?.includes('\\n')) {
      json[key] = json[key].replace('\\n', '\n');
    }
  });

  return json;
};

export const updateHtmlRootLanguageAttributes = (
  locale: ISO6391LanguageCode
): void => {
  //TODO https://hustro.atlassian.net/browse/PT-5934
  // setHtmlRootTextDirectionAttribute(locale);
  setHtmlRootLangAttribute(locale);
};

//TODO https://hustro.atlassian.net/browse/PT-5934
const setHtmlRootTextDirectionAttribute = (
  locale: ISO6391LanguageCode
): void => {
  document.dir = getTextDirectionByLocale(locale);
};
export function getTextDirectionByLocale(
  locale: string | ISO6391LanguageCode
): Direction {
  return locale === ISO6391LanguageCode.ar ? 'rtl' : 'ltr';
}

export function getDefaultLocale(): ISO6391LanguageCode {
  const currentBrowserLocale: string | undefined =
    navigator.language.slice(0, 2);
  if (!currentBrowserLocale) {
    return ISO6391LanguageCode.en;
  }
  // https://github.com/date-fns/date-fns/issues/2963
  if (currentBrowserLocale === 'nb' || currentBrowserLocale === 'nn') {
    return ISO6391LanguageCode.no;
  }
  if (isSupportedLocale(currentBrowserLocale)) {
    return currentBrowserLocale;
  }
  return ISO6391LanguageCode.en;
}

export function getDefaultCountryCode(): string {
  const browserLocale = navigator.language;
  if (browserLocale) {
    const countryCode = browserLocale.split('-')[1];
    if (countryCode && countryCode.length === 2) {
      return countryCode.toUpperCase();
    }
  }
  return 'US';
}

function getInitialLocaleCode(intl: IntlShape): string {
  return (
    intl.locale || navigator.language.slice(0, 2) || ISO6391LanguageCode.en
  );
}

export function getInitalLocale(intl: IntlShape): AppLocaleOnView {
  return anyToAppLocaleOnView(getInitialLocaleCode(intl));
}
