export enum LocaleCodeDto {
  en = 'en',
  ar_AE = 'ar-AE',
  bg_BG = 'bg-BG',
  hr_HR = 'hr-HR',
  cs_CZ = 'cs-CZ',
  fr = 'fr',
  de_DE = 'de-DE',
  he_IL = 'he-IL',
  hu_HU = 'hu-HU',
  no = 'no',
  pl = 'pl',
  pt_PT = 'pt-PT',
  ro = 'ro',
  ru_UA = 'ru-UA',
  sk_SK = 'sk-SK',
  es = 'es',
  tr_TR = 'tr-TR',
  uk_UA = 'uk-UA',
}
